<template>
  <v-combobox
    class="mt-n4"
    :value="$store.getters.getClientTags"
    :filter="filter"
    :hide-no-data="!search"
    :items="$store.getters.getTagsList"
    :search-input.sync="search"
    hide-selected
    label="Искать.."
    multiple
    small-chips
    solo
    @change="(e) => updateTags(e)"
  >
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading">Создать&nbsp;</span>
        <v-chip color="rgba(0,0,0, .12)" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :color="`${item.color}`"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">
          {{ item.text }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-chip :color="`${item.color}`" label small>
        {{ item.text }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<style scoped></style>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    selectedUser: {
      type: Object,
      default: () => {
        {return {
          login: "",
          name: "",
        }};
      },
    },
  },
  data: () => ({
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    nonce: 1,
    menu: false,
    search: null,
  }),

  watch: {
  },

  methods: {
    ...mapActions(["UpdateUsersTags", "GetUserAllPrivs", "GetUsers"]),
    async updateTags(e) {
      await this.UpdateUsersTags({user_id: this.selectedUser.id, tags: e});
      await this.GetUsers();
      await this.GetUserAllPrivs(this.selectedUser.id);
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
};
</script>
