<template>
  <div class="ml-2" style="height: 100%; max-height: 100%">
    <div class="mt-4 ml-8">
      <v-checkbox
        label="Панель по умолчанию"
        :input-value="!$store.getters.getClientSources.length"
        @click="clearUserSourcesPrivs"
      />
      <p class="mt-n2 mb-4 ml-8">
        доступный источник данных, по умолчанию всем клиентам доступен источник MediaHills.Взвешенная панель, ссылается на helper_sources.id
      </p>
    </div>

    <v-divider />

    <div class="mt-6 ml-8">
      <v-checkbox
        v-for="source in $store.getters.getAdminSourcesList" :key="source.id"
        class="mt-n2"
        :input-value="source.checked"
        :label="source.name"
        @change="(e) => updateUserSourcesPriv(source.id, e)"
      ></v-checkbox>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Utils from "../services/utils";

export default {
  props: {
    privSection: {
      type: String,
      default: "",
    },
    selectedUser: {
      type: Object,
      default: () => {
        {return {
          login: "",
          name: "",
        }};
      },
    },
  },
  mounted() {
    this.user = this.selectedUser;
    this.selectedSources = this.$store.getters.getSourcesPrivs;
    //this.allSources = this.$store.getters.getClientSources.length==0;
    //this.allSources = this.selectedSources.length ? false : true;
    //console.log(this.selectedSources);
  },
  data: () => ({
    user: null,
    allSources: null,
    selectedSources: []
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    ...mapActions(["UpdateSourcesPriv", "GetUserAllPrivs"]),
    async clearUserSourcesPrivs() {
      await this.UpdateSourcesPriv({ id: this.user.id, sourceId: null, sourceValue: null });
      await this.GetUserAllPrivs(this.user.id);
    },
    async updateUserSourcesPriv(sourceId, sourceValue) {
      console.log(sourceId, sourceValue);
      await this.UpdateSourcesPriv({ id: this.user.id, sourceId, sourceValue });
      await this.GetUserAllPrivs(this.user.id);
    },
    range(min, max) {
      return Utils.range(+min, +max);
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
  },
  watch: {
    selectedUser: function (value) {
      this.user = value;
      this.selectedSources = this.$store.getters.getSourcesPrivs;
    //  this.allSources = this.$store.getters.getClientSources.length==0;
    // this.allSources = this.selectedSources.length ? false : true;
    //  console.log(value);
      console.log(this.selectedSources);
    },
  },
};
</script>
