<template>
  <div style="height: 100%; max-height: 100%">
    <v-row class="mt-1" v-if="privSection=='getSectionsPrivs' ">
      <v-col cols="12">
        <div class="d-flex">
          <h4>Личные кабинеты</h4>
          <v-btn-toggle
            dense
            class="ml-4 mb-2"
            active-class="toggledPriv"
            v-model="user.stat"
            @change="(e) => updateUserLk(e)"
          >
            <v-btn
              v-for="val in range(-1, 1)"
              :key="val"
              x-small
              dense
              class="privButtons"
              >{{val}}</v-btn
            >
          </v-btn-toggle>
        </div>
        <p>
          Клиент может логиниться только в основной ЛК: 0<br>
          Клиент может логиниться только в альтернативный ЛК: 1<br>
          Клиент может логиниться во все ЛК: -1
        </p>
        <v-divider class="mb-n1 mt-4" />
      </v-col>

    </v-row>
    <v-row class="mt-1" v-for="priv in $store.getters[privSection]" :key="priv.id">
      <v-col cols="12">
        <div class="d-flex">
          <h4>{{ priv.title }}</h4>
          <v-btn-toggle
            dense
            class="ml-4 mb-2"
            active-class="toggledPriv"
            v-model="priv.selected"
            @change="(e) => updateUserPriv(priv.id, e, priv.priv_min)"
          >
            <v-btn
              v-for="val in range(priv.priv_min, priv.priv_max)"
              :key="val"
              x-small
              dense
              class="privButtons"
              >{{val}}</v-btn
            >
          </v-btn-toggle>
        </div>
        {{priv.comment}}
        <v-divider class="mb-n1 mt-4" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}
.toggledPriv {
  background-color: rgba(129, 199, 132, 1)!important;
}
.tabDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.58);
}
.cardSubheader {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.58);
}
</style>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import Utils from "../services/utils";

export default {
  props: {
    privSection: {
      type: String,
      default: "",
    },
    selectedUser: {
      type: Object,
      default: () => {
        {return {
          login: "",
          name: "",
        }};
      },
    },
  },
  mounted() {
    this.user = this.selectedUser;
  },
  data: () => ({
    user: null,
    privValue: null,
    selectedLk: 0,
  }),
  computed: {
    now() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    ...mapActions(["UpdatePriv", "GetUserAllPrivs", "SaveUser"]),
    async updateUserPriv(privId, privValue, privMin) {
      const privRealValue = Number(privValue) + Number(privMin);
      //console.log(privId, privValue, privMin, privRealValue);
      await this.UpdatePriv({id: this.user.id, privId, privValue: privRealValue})
      //await this.GetUserAllPrivs(this.user.id);
    },
    async updateUserLk(value) {
      this.$store.commit("updateUser", {userId: this.user.id, stat: value - 1});
      await this.SaveUser({ id: this.user.id, stat: value - 1});            
    },
    range(min, max) {
      return Utils.range(+min, +max);
    },
    formatDate(dt) {
      return moment(dt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
    },
  },
  watch: {
    selectedUser: function () {
      this.user = this.selectedUser;
      //console.log(value);
    },
  },
};
</script>
